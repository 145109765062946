import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { isLogin } from './login/guard/isLogin.guard';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'hospedaje', loadChildren: () => import('./hospedaje/hospedaje.module').then(m => m.HospedajeModule) },
  { path: 'experiencias', loadChildren: () => import('./experiencias/experiencias.module').then(m => m.ExperienciasModule) },
  { path: 'eventos', loadChildren: () => import('./eventos/eventos.module').then(m => m.EventosModule) },
  { path: 'sustentabilidad-accecibilidad', loadChildren: () => import('./sustentabilidad/sustentabilidad.module').then(m => m.SustentabilidadModule) },
  { path: 'gastronomia', loadChildren: () => import('./gastronomia/gastronomia.module').then(m => m.GastronomiaModule) },
  { path: 'nosotros', loadChildren: () => import('./nosotros/nosotros.module').then(m => m.NosotrosModule) },
  { path: 'blog', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule) },
  { path: 'nuestrahistoria', loadChildren: () => import('./nuestrahistoria/nuestrahistoria-routing.module').then(m => m.NuestrahistoriaRoutingModule) },
  { path: 'proyectoderestauracion', loadChildren: () => import('./proyectoderestauracion/proyectoderestauracion-routing.module').then(m => m.ProyectoderestauracionRoutingModule) },
  { path: 'destinos-inteligentes', loadChildren: () => import('./destinos-inteligentes/destinos-inteligentes.module').then(m => m.DestinosInteligentesModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'PageNotFound', loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [isLogin] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
