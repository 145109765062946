<mat-toolbar color="primary">
    <mat-toolbar-row>
        <!-- <button (click)="toggleSidenav()" mat-icon-button>
            <mat-icon>menu</mat-icon>
        </button> -->
        <div class="links">
            <button mat-raised-button color="warn" routerLink="/admin/posts">POSTS</button>
        <button mat-raised-button color="warn" routerLink="/admin/destinos">DESTINOS INTELIGENTES</button>
        </div>
        <span class="spacer"></span>

        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="logOut()">
                <mat-icon>exit_to_app</mat-icon>
                <span>Cerrar Sesión</span>
            </button>
        </mat-menu>
    </mat-toolbar-row>
</mat-toolbar>