<div class="header-content">
    <div class="welcome" [ngStyle]="{'background-image': 'url('+ path + ')'}">
        <div class="mask-header">
            <div class="content">
                <div class="saludo">
                    <h1><span>{{saludo}},</span> bienvenidos!</h1>
                </div>

                <div class="content-widgets">
                    <div class="tiempo bg" *ngIf="weather">
                        <div class="clima">{{weather.main.temp}}<span>ºC</span></div>
                        <div class="city">Concordia, E.R.</div>
                    </div>
                    <div class="reloj bg">
                        <div class="hora">
                            <div class="numbers">
                                {{ ahora | date: 'hh:mm'}}
                            </div>
                            <span>{{ ahora | date: 'a'}}</span>
                        </div>
                        <div class="fecha">
                            <span>{{fecha.diaSemana}}</span>
                            <span>{{fecha.dia}} de {{fecha.mes}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow">
            <mat-icon>expand_more</mat-icon>
        </div>
    </div>
</div>