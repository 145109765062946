import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DataTimeService } from './../../shared/services/data-time.service';
import { ClockService } from './../../shared/services/clock.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public saludo: string;
  public path: string;
  public iconDay: string;

  public weather;
  public clock: Date;
  presentWatch = false;

  fecha: { dia: string, diaSemana: string , mes: string } = { dia: '', diaSemana: '', mes: '' };
  ahora: Date = new Date();

  constructor(
    private dataTimeSVC: DataTimeService,
    // private clockSVC: ClockService
  ) { }

  ngOnInit(): void {
    this.bgHeader();
    this.getDataTime();
    let minutes = 5;
    // setInterval(()=>this.getDataTime(),1000*60*minutes);
    setInterval(() => {
      this.fecha.diaSemana = formatDate(this.ahora, 'EEEE', 'es');
      this.fecha.dia = formatDate(this.ahora, 'd', 'es');
      this.fecha.mes = formatDate(this.ahora, 'LLL', 'es');
      this.ahora = new Date();
      this.presentWatch = true;
    }, 1000);
  }

  getDataTime() {
    this.dataTimeSVC.onGetAPI().subscribe(time => {
      this.weather = time;
      // console.log(this.weather);
    })
  }

  bgHeader(){
    var ahora = new Date;
      var hora = ahora.getHours();

      if (hora >= 6 && hora < 20) {
        this.iconDay = "sol";
      } else {
        this.iconDay = "luna";
      }

      if (hora >= 6 && hora < 13) {
        this.saludo = "Buenos días";
        this.path = '../../assets/img/bg/buendia2.jpg';
      } else if (hora >= 13 && hora < 20) {
        this.saludo = "Buenas tardes";
        this.path = '../../assets/img/bg/buenastardes2.jpg';
      } else {
        this.saludo = "Buenas noches";
        this.path = '../../assets/img/bg/buenasnoches2.jpg';
      }
   
  }

}
