import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './../../shared/services/auth.service';
import { map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class isLogin implements CanActivate {

    constructor(private authSVC: AuthService, private _router: Router) { }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        if(this.authSVC.isLogged) {
            return true;
        }else {
            this._router.navigateByUrl('/login');
            return false;
        }
        // return this.authSVC.user$.pipe(
        //     map(user => {
        //         if (!user) {
        //             this._router.navigateByUrl('/login');
        //             return false;
        //         }
        //         return true;
        //     })
        // );
    }

}