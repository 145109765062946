import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../class/user.class';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  public currentUser: User = new User();

  private userCollection: AngularFirestoreCollection<User>;
  public users: Observable<User[]>;

  constructor(private afs: AngularFirestore, private authSVC: AuthService) {
    this.userCollection = afs.collection<User>('users');
    this.users = this.userCollection.snapshotChanges().pipe(map(
      actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      }
    ));
   }

   getAllUsers(){
     return this.users;
   }
}
