<div class="grid" [ngStyle]="{'background-color' : toggleNavSVC.bgSelected}">
    <div class="logo">
        <img src="../../assets/logos/logo-header.svg" alt="">
    </div>

    <div class="menu">
        <button [routerLink]="item.href" *ngFor="let item of itemsMenu">{{item.item}}</button>
        <div class="rrss">
            <a class="icon" routerLink="/login">
                <img src="../../../../assets/icons/user.svg" width="15" alt="">
            </a>
            <a class="icon" href="https://www.instagram.com/establecimientolangelica/" target="_blank">
                <img src="../../assets/icons/Instagram.svg" width="17" alt="">
            </a>
            <a class="icon" href="https://www.facebook.com/EstablecimientoLaAngelica/" target="_blank">
                <img src="../../assets/icons/Facebook.svg" width="10" alt="">
            </a>
            <a class="icon" href="https://www.youtube.com/channel/UCfua_d0m4UWfv2SfZMiBc7w" target="_blank">
                <img src="../../assets/icons/Youtube.svg" width="18" alt="">
            </a>
        </div>
    </div>

    <div class="toggle-menu">
        <button (click)="toggle()" *ngIf="!isOpen">
            <mat-icon>menu</mat-icon>
        </button>
        <button (click)="toggle()" *ngIf="isOpen">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div [@toggle]="isOpen? 'open' : 'closed'" class="menu-smart-grid" [ngStyle]="{'background-color' : toggleNavSVC.bgSelected}">
    <!-- <div class="pages">
        <button [routerLink]="item.href" *ngFor="let item of itemsMenu" (click)="toggle()">{{item.item}}</button>
    </div> -->
    <ul>
        <li><a [routerLink]="item.href" *ngFor="let item of itemsMenu" (click)="toggle()">{{item.item}}</a></li>
    </ul>
    <div class="rrss">
        <a class="icon" href="https://www.instagram.com/establecimientolangelica/" target="_blank">
            <img src="../../assets/icons/Instagram.svg" width="17" alt="">
        </a>
        <a class="icon" href="https://www.facebook.com/EstablecimientoLaAngelica/" target="_blank">
            <img src="../../assets/icons/Facebook.svg" width="10" alt="">
        </a>
        <a class="icon" href="https://www.youtube.com/channel/UCfua_d0m4UWfv2SfZMiBc7w" target="_blank">
            <img src="../../assets/icons/Youtube.svg" width="18" alt="">
        </a>
    </div>
</div>