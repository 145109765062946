import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { SendMailService } from 'src/app/shared/services/send-mail.service';
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-contacto-home',
  templateUrl: './contacto-home.component.html',
  styleUrls: ['./contacto-home.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ]
})
export class ContactoHomeComponent implements OnInit, OnDestroy {

  subs: Subscription[] = [];

  @Input() title?: string;

  showMjs: boolean = false;
  persons: number[] = new Array(10).fill(0).map((e, idx) => idx);

  contactForm = new FormGroup({
    name_lastname: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    checkIn: new FormControl('', [Validators.required]),
    checkOut: new FormControl('', [Validators.required]),
    persons: new FormControl(1, [Validators.required]),
    telefono: new FormControl('', [Validators.required]),
    comentario: new FormControl('', [Validators.required]),
  })

  constructor(private SendMailSVC: SendMailService) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  blockBTN: boolean = false;

  sendForm() {
    this.blockBTN = true;
    let checkIn: string = this.contactForm.value.checkIn._i.date + '/' + (this.contactForm.value.checkIn._i.month + 1) + '/' + this.contactForm.value.checkIn._i.year;
    let checkOut: string = this.contactForm.value.checkOut._i.date + '/' + (this.contactForm.value.checkOut._i.month + 1) + '/' + this.contactForm.value.checkOut._i.year;

    const obj2Send = {
      name_lastname: this.contactForm.value.name_lastname,
      email: this.contactForm.value.email,
      checkIn: checkIn,
      checkOut: checkOut,
      persons: this.contactForm.value.persons,
      telefono: this.contactForm.value.telefono,
      comentario: this.contactForm.value.comentario
    }

    const sub = this.SendMailSVC.sendMailContacto(obj2Send).subscribe(res => {
      if (res.status == 'Ok') {
        console.log('Mensaje Enviado');
        this.blockBTN = false;
        this.showMjs = true;
        this.contactForm.reset();
        this.contactForm.get('persons').setValue(1);
        setTimeout(() => {
          this.showMjs = false;
        }, 8000);
      } else {
        this.blockBTN = false;
      }
    }, err => {
      this.blockBTN = false;
      console.error(err);
    });

    this.subs.push(sub);
  }

}
