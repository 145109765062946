import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.scss']
})
export class SharedComponent implements OnInit {

  rrss = [
    {
      name: 'whatsapp',
      icon: '../../../../assets/icons/wsp.svg',
      color: '#25d366'
    },
    {
      name: 'facebook',
      icon: '../../../../assets/icons/Facebook.svg',
      color: '#3b5998'
    },
    {
      name: 'twitter',
      icon: '../../../../assets/icons/logo_twitter.svg',
      color: '#00acee'
    },
    {
      name: 'googlePlus',
      icon: '../../../../assets/icons/logo_google+.svg',
      color: '#DD4B39'
    }
  ]

  constructor(private _bottomSheetRef: MatBottomSheetRef<SharedComponent>) { }

  ngOnInit(): void {    
  }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

}
