import { Component, OnInit } from '@angular/core';
import { SidenavService } from '../../services/sidenav.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar-admin',
  templateUrl: './navbar-admin.component.html',
  styleUrls: ['./navbar-admin.component.scss']
})
export class NavbarAdminComponent implements OnInit {

  constructor(private sidenav: SidenavService, private authSVC: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  logOut() {
    this.authSVC.logOutUser();
    this.router.navigateByUrl('/');
  }

  toggleSidenav() {
    this.sidenav.toggle();
  }

}
