import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from '../class/user.class';
import { Router } from '@angular/router';
import { UsersService } from './users.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isLogged: any = false;
  public isError: boolean = false;
  public errors: string = '';

  constructor(public afAuth: AngularFireAuth) { 
    afAuth.authState.subscribe(user => (this.isLogged = user));
  }

  isAuth(){
    return this.afAuth.authState.pipe(map(auth => auth));
  }

  // Login Email & Password
  async onLoginEmail(user: User) {
    try {
      return await this.afAuth.signInWithEmailAndPassword(user.email, user.password);
    } catch (error) {

      if (error.code == 'auth/argument-error') {
        this.isError = true;
        this.errors = 'El usuario o la contraseña no son válidos.';
        setTimeout(() => {
          this.isError = false;
          this.errors = '';
        }, 5000);
      }

      if (error.code == 'auth/user-not-found') {
        this.isError = true;
        this.errors = 'El usuario ingresado no es válido.';
        setTimeout(() => {
          this.isError = false;
          this.errors = '';
        }, 5000);
      }

      if (error.code == 'auth/wrong-password') {
        this.isError = true;
        this.errors = 'La contraseña ingresada no es válida.';
        setTimeout(() => {
          this.isError = false;
          this.errors = '';
        }, 5000);
      }
    }
  }

  logOutUser(){ 
    return this.afAuth.signOut();
  }
}
