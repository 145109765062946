import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './home/header/header.component';
import { NavbarAdminComponent } from './shared/components/navbar-admin/navbar-admin.component';
import { ConfirmComponent } from './admin/confirm/confirm.component';


//Material
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { SharedComponent } from './shared/components/shared/shared.component';
import { ContactoHomeComponent } from './home/contacto-home/contacto-home.component';

@NgModule({
    declarations:[
        NavbarComponent,
        FooterComponent,
        HeaderComponent,
        NavbarAdminComponent,
        ConfirmComponent,
        SharedComponent,
        ContactoHomeComponent
    ],
    exports:[
        NavbarComponent,
        FooterComponent,
        HeaderComponent,
        NavbarAdminComponent,
        ConfirmComponent,
        MatDatepickerModule,
        MatSelectModule,
        ContactoHomeComponent
    ],
    imports:[
        CommonModule,
        RouterModule,
        MatToolbarModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatButtonModule,
        MatDialogModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule
    ]
})

export class ComponentsModule{}