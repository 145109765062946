import { Component, OnInit, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';
import { ToggleNavService } from '../../services/toggle-nav.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('toggle', [
      state('closed', style({
        height: '0px',
        opacity: 0
      })),
      state('open', style({
        height: '93%',
        opacity: 1
      })),
      transition('closed => open', [
        animate('.3s ease-out')
      ]),
      transition('open => closed', [
        animate('.2s ease-out')
      ])
    ])
  ]
})
export class NavbarComponent implements OnInit {

  isOpen: boolean = false;

  itemsMenu = [
    {
      item: 'inicio',
      href: '/home'
    },
    {
      item: 'hospedaje',
      href: '/hospedaje'
    },
    {
      item: 'experiencias',
      href: '/experiencias'
    },
    {
      item: 'gastronomía identitaria',
      href: '/gastronomia'
    },
    {
      item: 'sustentabilidad y accesibilidad',
      href: '/sustentabilidad-accecibilidad'
    },
    {
      item: 'eventos',
      href: '/eventos'
    },
    {
      item: 'noticias',
      href: '/blog'
    }
  ]

  constructor(private router: Router, public toggleNavSVC: ToggleNavService) { }

  ngOnInit(): void {
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  // @HostListener("window:scroll", []) onWindowScroll() {
  //   const verticalOffset = window.pageYOffset
  //     || document.documentElement.scrollTop
  //     || document.body.scrollTop || 0;
  //   if (verticalOffset > 50) {
  //     document.getElementById('nav-bar').classList.remove('nav-no-collapse');
  //     document.getElementById('nav-bar').classList.add('nav-collapse');

  //   } else {
  //     document.getElementById('nav-bar').classList.add('nav-no-collapse')

  //   }
  // }

}
