import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SendMailService {

  baseUrl = 'https://us-central1-angelicaweb-39a01.cloudfunctions.net/sendMail';

  constructor(private http: HttpClient) { }

  sendMailContacto(data){
    return this.http.post<any>(this.baseUrl, JSON.stringify(data));
  }
}
