<div class="footer">
    <div class="content">
        <div class="logo">
            <img src="../../assets/logos/logo-responsive.svg" alt="">
        </div>
        <div class="icons">
            <img src="./../../../../assets/icons/group/icono_cafe.svg" alt="">
            <img src="./../../../../assets/icons/group/icono_casa.svg" alt="">
            <img src="./../../../../assets/icons/group/icono_hamaca.svg" alt="">
            <img src="./../../../../assets/icons/group/icono_largavista.svg" alt="">
            <img src="./../../../../assets/icons/group/icono_pelota.svg" alt="">
            <img src="./../../../../assets/icons/group/icono_pile.svg" alt="">
            <img src="./../../../../assets/icons/group/icono_sendero.svg" alt="">
        </div>
    </div>
</div>

<div class="creditos">
    © 2020 Copyright: Estudio Más Creativo / Todos los derechos reservados.
</div>