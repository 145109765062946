import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToggleNavService {

  visible: boolean;

  verde: string = '#60beb0';
  marron: string = '#7a5f47';

  bgSelected: string = this.verde;

  constructor() { }

  hide() { this.visible = false; }

  show() { this.visible = true; }

  toggle() { this.visible = !this.visible; }
}
