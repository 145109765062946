import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataTimeService {

  constructor(private http: HttpClient) { }

  private apiURL = 'https://api.openweathermap.org/data/2.5/weather?q=Concordia,arg&appid=e4ad569f09a8f5e888c4770f961a1c62&units=metric&q=';

  onGetAPI() {
    return this.http.get(`${this.apiURL}`);
  }
  
}
