// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCXgQ3ELS5qlpwd08HfzPUVICJ1gWFsESc",
    authDomain: "angelicaweb-39a01.firebaseapp.com",
    databaseURL: "https://angelicaweb-39a01.firebaseio.com",
    projectId: "angelicaweb-39a01",
    storageBucket: "angelicaweb-39a01.appspot.com",
    messagingSenderId: "1071717592940",
    appId: "1:1071717592940:web:a2207312ee946ca43d4d49",
    measurementId: "G-EB4KW7SPPE"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
