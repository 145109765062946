<div class="content">
    <div class="form">
        <div class="title">{{title}}</div>
        <form [formGroup]="contactForm">
            <div class="row">
                <div class="col-12 col-md-6">
                    <mat-form-field class="example-full-width">
                        <mat-label>Nombre y Apellido</mat-label>
                        <input autocomplete="off" matInput placeholder="Tu nombre y apellido" formControlName="name_lastname">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="example-full-width">
                        <mat-label>email</mat-label>
                        <input autocomplete="off" matInput type="email" placeholder="Tu correo electrónico" formControlName="email">
                    </mat-form-field>
                </div>

                <div class="col-6 col-md-3">
                    <mat-form-field class="example-full-width">
                        <mat-label>CkeckIn</mat-label>
                        <input autocomplete="off" matInput [matDatepicker]="picker1" formControlName="checkIn">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-6 col-md-3">
                    <mat-form-field class="example-full-width">
                        <mat-label>CheckOut</mat-label>
                        <input autocomplete="off" matInput [matDatepicker]="picker2" formControlName="checkOut">
                        <mat-datepicker-toggle class="icon" matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-4 col-md-2">
                    <mat-form-field class="example-full-width">
                        <mat-label>Cant. Personas</mat-label>

                        <mat-select formControlName="persons">
                            <mat-option *ngFor="let cant of persons" [value]="cant+1">
                              {{cant+1}} pers.
                            </mat-option>
                          </mat-select>
                        
                    </mat-form-field>
                </div>

                <div class="col-8 col-md-4">
                    <mat-form-field class="example-full-width">
                        <mat-label>Teléfono</mat-label>
                        <input autocomplete="off" matInput formControlName="telefono">
                    </mat-form-field>
                </div>



                <div class="col-12">
                    <mat-form-field class="example-full-width">
                        <mat-label>Déjanos tu consulta</mat-label>
                        <textarea matInput rows="3" formControlName="comentario"></textarea>
                    </mat-form-field>
                </div>
            </div>
            
            <div class="row mt-3 align-items-center">
                <div class="col-12 col-md-6">
                    <span class="text-success" *ngIf="showMjs">
                        Mensaje enviado con éxito. <br>Te responderemos a la brevedad
                    </span>
                </div>
                <div class="col-12 col-md-6 text-right"> 
                    <button class="propio" [disabled]="!contactForm.valid || blockBTN" (click)="sendForm()">Consúltanos!</button>
                </div>
            </div>
        </form>
    </div>
    <div class="frase">
        <span>
            ...conocer para querer, <br>
            querer para conservar.
        </span>
    </div>
</div>

<div class="tarjetas">
    <img class="mp-logo" src="../../assets/mp-logo.png" alt="">
    <img class="cards" src="../../assets/tarjetas.png" alt="">
    <img class="pre-viaje" src="../../assets/previaje-logo.png" alt="">
</div>